import React from 'react';
import styled from 'react-emotion';
import deloitteLogoPath from '../images/deloitte_logo_gray.svg';
import edenLogoPath from '../images/edenspiekermann_logo_gray.svg';
import microsoftLogoPath from '../images/microsoft_logo_gray.svg';
import deloitteLogoWhitePath from '../images/deloitte_logo_white.svg';
import cpLogo from '../images/cp_logo.png';
import cpLogoDark from '../images/cp_logo_dark.png';
import lmiLogo from '../images/lmi-logo.png';
import edenspiekermannLogoWhitePath from '../images/edenspiekermann_logo_white.svg';
import microsoftLogoWhitePath from '../images/microsoft_logo_white.svg';
import Item from '../components/grid/Item';
import { LogoImage } from '../components/Images';

const CompaniesWrapper = styled(Item)`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
  padding-top: 0px;
  background-color: none;
  @media (min-width: 320px) {
    width: 100%;
    float: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (min-width: 500px) {
    width: calc((10/12)*100%);
    margin-left: calc((1/12)*100%);
  }
`;

const Logos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Companies = ({ children, isWhite }) => (
  <CompaniesWrapper>
    {children}
    <Logos>
      <LogoImage
        src={isWhite ? deloitteLogoWhitePath : deloitteLogoPath}
        alt="Cogo Customer - Deloitte"
      />
      <LogoImage
        src={isWhite ? microsoftLogoWhitePath : microsoftLogoPath}
        alt="Cogo Customer - Microsoft"
      />
      <LogoImage
        src={isWhite ? edenspiekermannLogoWhitePath : edenLogoPath}
        alt="Cogo Customer - Eden Spiekermann"
      />
      <LogoImage
        src={isWhite ? lmiLogo : lmiLogo}
        alt="Cogo Customer - Logmein"
      />
    </Logos>
  </CompaniesWrapper>
);

export default Companies;

import React from 'react';
import styled from 'react-emotion';
import { colors } from '../components/styles';

export const FlatlayDarkImg = styled.img`
  box-shadow: 0px 0px 20px 0px ${colors.gray};
  border-radius: 0.3em;
  margin-top: 40px;
  margin-bottom: 80px;
  padding: 0;
  width: 100%;
  max-width: 100%;
  @media (min-width: 320px) {
    margin-bottom: 20px;
  }
  @media (min-width: 690px) {
    margin-bottom: 80px;
  }
  @media (min-width: 1140px) {
    box-shadow: 0px 0px 36px 0px ${colors.ink};
  }
`;

export const FlatlayImg = styled.img`
  box-shadow: 0px 0px 36px 0px ${colors.mist};
  border-radius: 0.3em;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 0;
  max-width: 100%;
  width: 100%;
  @media (min-width: 320px) {
    box-shadow: 0px 0px 20px 0px ${colors.mist};
  }
  @media (min-width: 1140px) {
    box-shadow: 0px 0px 36px 0px ${colors.mist};
  }
`;

export const RoundImg = styled.img`
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 380px;
  margin: 0 auto;
`;

export const IntegrationsImg = styled.img`
  max-height: 50px;
  max-width: 170px;
  margin: 10px;
`;

export const ResponsiveImg = styled.img`
  width: 100%;
`;

export const LogoImage = styled.img`
  max-width: 140px;
  margin: 20px;
  max-height: 50px;
`;
